import React from "react";

import { graphql } from "gatsby";

import News from "../../../components/pages/News";

const GemeinnuetzigkeitIndexPage = (props) => <News {...props} types={[1]} />;
export default GemeinnuetzigkeitIndexPage;

export const query = graphql`
    query {
        laravelLighthouse {
            articles: newsArticles(
                orderBy: { order: DESC, column: DATE_PUBLISHED }
                isPublished: true
                section: "Gemeinnützigkeit"
            ) {
                id
                abstract
                articleBody
                createdAt
                updatedAt
                datePublished
                image
                keywords
                section
                slug
                title
                titleAlignment
                video
            }
        }
        page: pagesYaml(templateKey: { eq: "pages/gemeinnuetzigkeit" }) {
            ...Page
            startCount
            loadCount
            headline
            elements {
                ...EntireElement
            }
        }
    }
`;
